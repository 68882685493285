@import '_colours.scss';
@import '_fonts.scss';

body {
    font-family: $font;
    font-size: 15px;
    color: $body-font;
    background-color: $pale-pink;
    font-weight: 300;
}
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
    max-width: 100%;
}
img {
    height:auto !important;
}

.clear-float {
    clear:both;
}
.dark-pink,
.page-content h1,
.page-content h2,
.page-content h3,
.page-content h4,
.highlight {
    color: $dark-pink;
}
.page-content h2 {
    font-size: 20px;
    margin-top: 2vw;
    margin-bottom: 2vw;
}
.highlight {
    font-size:20px;
}

.button {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
    padding: 10px 20px;
    max-width: 100%;
    color: $dark-pink;
    background-color: transparent;
    border: 1.5px solid $dark-pink;
    box-sizing: border-box;
    transition: all 0.3s ease;
}
.button:hover {
    color: $pale-pink;
    background-color: $dark-pink;
}

.round-image.display-left {
    float:left;
    margin-right: 2vw;
}

.round-image.display-right {
    float:right;
    margin-left: 2vw;
}

.round-image {
    position: relative;
    max-width: 400px;
    width: 100%;
    max-height:400px;
    border-radius: 50%;
    box-sizing: border-box;
    overflow: hidden;
    margin-bottom: 10px;
}
.round-image:before { /* ratio */
    content:"";
    display: block;
    width: 100%;
    padding-top: 100%;
}
.round-image img { /* position inside square */
    position: absolute;
    top:0;
    left:0;
    min-width: 100%;
    min-height: 100%;
}

/** page content expand items **/
.expand {
    box-sizing: border-box;
    margin: 30px 0;
    min-height: 50px;
}
.expand .title {
    position: relative;
    display: inline-block;
    margin: 0;
}
/* expand toggles only when js enabled */
.js .expand > h3.title {
    cursor: pointer;
    max-width: 900px;
    width: 100%;
    text-transform: inherit;
}
.js .expand > h3.title:after {
    content: '';
    position:absolute;
    background-image: url("../assets/arrow.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    width: 20px;
    height:20px;
    top: 50%;
    right: 0;
    display: block;
    transition: all 0.5s ease;
    margin: 0 20px;
}
.js .expand.active > h3.title:after { /*cross*/
    -webkit-transform: rotate(180deg) translate(0,0);
    transform: rotate(180deg) translate(0,0);
}
.js .expand > .body {
    opacity: 0;
    transition: opacity 0.3s ease;
    overflow: hidden;
}
.js .expand.active > .body {
    opacity: 1;
}

@media only screen and (max-width: 760px){
    .round-image.display-left,
    .round-image.display-right {
        float: none;
        margin: 0 auto;
        margin-bottom: 10px;
    }
}